import React, { useEffect } from "react";
import useScrollPosition from "../js/useScrollPosition";
import Collapsible from 'react-collapsible';
import { Router, Route } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';
import { FaFacebook } from "react-icons/fa";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-creative";
import larisa from '../img/Larisa_foto.png';
import educationFor1 from '../img/educationFor1.png';
import educationFor2 from '../img/educationFor2.png';
import educationFor3 from '../img/educationFor3.png';
import educationFor5 from '../img/educationFor5.png';
import differentAge1 from '../img/differentAge1.png';
import differentAge2 from '../img/differentAge2.png';
import differentAge3 from '../img/differentAge3.png';
import likeButton from '../img/likeButton.png';
import teacher_larisa from '../img/larisa.jpg';
import teacher_anastasija from '../img/anastasija.png';
import teacher_anna_a from '../img/anna_a.jpg';
import teacher_anna_m from '../img/anna_m.png';
import teacher_din from '../img/din.jpg';
import teacher_igor from '../img/igor.jpg';
import teacher_jelena_lazareva from '../img/jelena_lazareva.jpeg';
import teacher_logo from '../img/teacherLogo.png';
import teacher_regina from '../img/regina.jpg';
import teacher_violetta from '../img/violetta.png';
import teacher_jekaterina_m from '../img/jekaterina_m.jpeg';
import teacher_jekaterina from '../img/katerina.jpeg';
import teacher_polina from '../img/polina.jpg';
import teacher_olga from '../img/olga.jpeg';
import teacher_jevgenija from '../img/jevgenija.jpg';
import teacher_jelena_a from '../img/jelena_a.jpg';
import teacher_erik from '../img/erik.jpeg';
import teacher_anelija from '../img/anelija.jpg';
import teacher_ludmila from '../img/ludmila.jpeg';
import teacher_angelika from '../img/angelika.png';
import teacher_alla from '../img/alla.jpeg';
import teacher_megan from '../img/megan.png';
import teacher_elizabeth from '../img/elizabeth.jpg';
import teacher_daniil from '../img/daniil.jpg';
import teacher_victoria from '../img/victoria.jpg';
import teacher_andrey from '../img/andrey.jpg';
import feedback1 from '../img/feedback1.jpg';
import feedback2 from '../img/feedback2.jpg';
import feedback3 from '../img/feedback3.jpg';
import feedback4 from '../img/feedback4.jpg';
import feedback5 from '../img/feedback5.jpg';
import feedback6 from '../img/feedback6.jpg';
import feedback7 from '../img/feedback7.jpg';
import line from '../img/line.png';
import sky1 from '../img/sky1.svg';
import sky2 from '../img/sky2.svg';
import sky3 from '../img/sky3.svg';
import sky4 from '../img/sky4.svg';
import sky5 from '../img/sky5.svg';
import sky6 from '../img/sky6.svg';
import sky1icon from '../img/sky1icon.png';
import sky2icon from '../img/sky2icon.png';
import sky3icon from '../img/sky3icon.png';
import sky4icon from '../img/sky4icon.svg';
import sky5icon from '../img/sky5icon.png';
import sky6icon from '../img/sky6icon.png';
import Footer from "../components/Footer";


const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const scrollPosition = useScrollPosition();

  return (
    <div className="home">
      <div className="home__container">
        <div className="home__intro">
          <img className="home__intro__larisa" src={larisa} alt="" />
          {/* <img src={homeMainBackground}></img> */}
          <div className="home__intro__content">
            <div className="home__intro__content__title">
              <div className="home__intro__content__title-first">
                <FormattedMessage id="home.intro.title1" defaultMessage="Математический центр" />
              </div>
              <div className="home__intro__content__title-second">
                <FormattedMessage id="home.intro.subtitle" defaultMessage="Ларисы Вагалэ" />
              </div>
              <div className="home__intro__content__title-first">
                <FormattedMessage id="home.intro.title2" defaultMessage="Математический центр" />
              </div>
            </div>
            <MessengerCustomerChat
              pageId="2174964776083029"
              appId="706372487322359"
            />
            <div className="home__intro__content__list">
              <ul>
                <li><FormattedMessage id="home.intro.content.li1" defaultMessage="Индивидуальный подход" /></li>
                <li><FormattedMessage id="home.intro.content.li2" defaultMessage="Дружелюбная атмосфера" /></li>
                <li><FormattedMessage id="home.intro.content.li3" defaultMessage="Увлекательный учебный процес" /></li>
              </ul>
            </div>
            <div className="home__intro__content__form-button" id='education-for'>
              <Link to='/contacts'>
                <FormattedMessage id="home.button" defaultMessage="Записаться на обучение" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    
      <div className="container" >
      {/*<div className="news">
          <div className="news__title"><FormattedMessage id="news.title1" defaultMessage="" /><span className="news__title--yellow"><FormattedMessage id="news.title2" defaultMessage="Актуальные" /></span><FormattedMessage id="news.title3" defaultMessage=" новости центра" /></div>
          <div className="news__list">
            <div className="news__list__item news__list__item--blue">
              <div className="news__list__item-title"><FormattedMessage id="news.section.title1" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text1" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text1.2" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text1.3" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text1.4" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text1.5" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.6" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.7" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.8" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.9" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.10" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text1.11" defaultMessage=" " /></div>
              <div className="news__list__item-text3"><FormattedMessage id="news.section.text1.12" defaultMessage=" " /></div>
            </div>
            <div className="news__list__item news__list__item--blue">
              <div className="news__list__item-title"><FormattedMessage id="news.section.title2" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.1" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.2" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text2.3" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text2.4" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text2.5" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text2.6" defaultMessage=" " /></div>
              <div className="news__list__item-text2"><FormattedMessage id="news.section.text2.7" defaultMessage=" " /></div><br></br>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.8" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.9" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.10" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.11" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.12" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.13" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.14" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text2.15" defaultMessage=" " /></div>
              <div className="news__list__item-text3"><FormattedMessage id="news.section.text2.16" defaultMessage=" " /></div>
            </div>
            <div className="news__list__item news__list__item--blue">
              <div className="news__list__item-title"><FormattedMessage id="news.section.title3" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.1" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.2" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.3" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.4" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.5" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.6" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.7" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.8" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.9" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.10" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.11" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.12" defaultMessage=" " /></div>
              <div className="news__list__item-text"><FormattedMessage id="news.section.text3.13" defaultMessage=" " /></div>
            </div>
          </div>
        </div>*/}

        <div className="education-for" >
          <div className="education-for__title"><FormattedMessage id="education.title1" defaultMessage="О" /><span className="education-for__title--yellow"><FormattedMessage id="education.title2" defaultMessage=" нас" /></span></div>
          <div className="education-for__list">
            <div className="education-for__list__item">
              <img className="education-for__list__item-image" src={educationFor1} alt=""></img>
              <div className="education-for__list__item-title"><span className="education-for__list__item-title bold"><FormattedMessage id="education.list1.title" defaultMessage="Подготовим к поступлению в престижный ВУЗ. " /></span><br />
                <FormattedMessage id="education.list1.body" defaultMessage="Работаем с программами различных университетов из разных стран и имеем подготовительную базу." /></div>
            </div>
            <div className="education-for__list__item">
              <img className="education-for__list__item-image" src={educationFor2} alt=""></img>
              <div className="education-for__list__item-title"><span className="education-for__list__item-title bold"><FormattedMessage id="education.list2.title" defaultMessage="Приведем к цели. " /></span> <br />
                <FormattedMessage id="education.list2.body" defaultMessage="Подтянем школьные знания, разъясним учебный материал, предоставить примеры и объяснения, чтобы улучшить понимание предметов и готовность к контрольным работам." /></div>
            </div>
            <div className="education-for__list__item">
              <img className="education-for__list__item-image" src={educationFor3} alt=""></img>
              <div className="education-for__list__item-title"><span className="education-for__list__item-title bold"><FormattedMessage id="education.list3.title" defaultMessage="Работаем с учениками из разных стран. " /></span> <br />
                <FormattedMessage id="education.list3.body" defaultMessage="География наших учеников не заканчивается на Латвии. Успешно работаем с учениками из Америки, Ирландии, Англии, Норвегии, Швейцарии, России и др." /></div>
            </div>
            <div className="education-for__list__item">
              <img className="education-for__list__item-image" src={educationFor5} alt=""></img>
              <div className="education-for__list__item-title"><span className="education-for__list__item-title bold"><FormattedMessage id="education.list4.title" defaultMessage="Адаптируем. " /></span><br />
                <FormattedMessage id="education.list4.body" defaultMessage="Составляем индивидуальную программу, благодаря которой ученик не только почувствует себя увереннее на уроках и экзаменах, не только улучшит свои оценки, но и научится верить в свои силы и умению не опускать руки, сталкиваясь с трудностями." /></div>
            </div>
          </div>
        </div>

        <div className="different-age">
          <div className="different-age__title"><FormattedMessage id="different.age.title1" defaultMessage="Обучение детей для " /><span className="different-age__title--yellow"><FormattedMessage id="different.age.title2" defaultMessage="разных возрастов" /></span><FormattedMessage id="different.age.title3" defaultMessage="" /></div>
          <div className="different-age__list">
            <div className="different-age__list__item different-age__list__item--blue">
              <div className="different-age__list__item-title"><FormattedMessage id="different.age.list.title1" defaultMessage="Начальная школа" /></div>
              <div className="different-age__list__item-text"><FormattedMessage id="different.age.list.text1" defaultMessage="Доброжелательная атмосфера в школе и авторские методические пособия прививают любовь к обучению и способствуют высоким академическим результатам" /></div>
              <div className="different-age__list__item-image" style={{ bottom: "-1%" }}><img src={differentAge1} alt=""></img></div>
              <div className="different-age__list__item-button"></div>
            </div>
            <div className="different-age__list__item different-age__list__item--blue">
              <div className="different-age__list__item-title"><FormattedMessage id="different.age.list.title2" defaultMessage="Средняя школа" /></div>
              <div className="different-age__list__item-text"><FormattedMessage id="different.age.list.text2" defaultMessage="Мы оптимизировали предметы. Интерактивный формат подачи учебного материала помогает более эффективно осваивать учебную программу" /></div>
              <div className="different-age__list__item-image"><img src={differentAge2} alt=""></img></div>
              <div className="different-age__list__item-button"></div>
            </div>
            <div className="different-age__list__item different-age__list__item--blue">
              <div className="different-age__list__item-title"><FormattedMessage id="different.age.list.title3" defaultMessage="Старшая школа" /></div>
              <div className="different-age__list__item-text"><FormattedMessage id="different.age.list.text3" defaultMessage="Подготовим к успешной сдаче экзамена и поступлению в ТОПовые университеты Латвии и мира" /></div>
              <div className="different-age__list__item-image"><img src={differentAge3} alt="img"></img></div>
              <div className="different-age__list__item-button"></div>
            </div>
          </div>
        </div>

        <div className="lesson-desc">
          <div className="lesson-desc__title"><span className="lesson-desc__title--yellow"><FormattedMessage id="lesson.desc.title1" defaultMessage="Как проходят уроки" /></span><FormattedMessage id="lesson.desc.title2" defaultMessage=" в математическом центре Ларисы Вагалэ" /></div>
          <div className="lesson-desc__line">
            <img className="lesson-desc__line-img" src={line} alt="" />
            <div className="lesson-desc__lineSky1">
              <img className="lesson-desc__lineSky1-img" src={sky1} alt="" />
              <img className="lesson-desc__lineSky1-icon" src={sky1icon} alt="" />
              <div className="lesson-desc__lineSky1-num">1</div>
              <div className="lesson-desc__lineSky1-text"><FormattedMessage id="lesson.desc.text1" defaultMessage="Ученик договаривается об времени постоянных занятий и в оговоренное время приходит на занятие или подключается дистанционно." /></div>
            </div>
            <div className="lesson-desc__lineSky2">
              <img className="lesson-desc__lineSky2-img" src={sky2} alt="" />
              <img className="lesson-desc__lineSky2-icon" src={sky2icon} alt="" />
              <div className="lesson-desc__lineSky2-num">2</div>
              <div className="lesson-desc__lineSky2-text"><FormattedMessage id="lesson.desc.text2" defaultMessage="Преподаватель перед уроком подбирает индивидуально по уровню ученика задания на урок." /></div>
            </div>
            <div className="lesson-desc__lineSky3">
              <img className="lesson-desc__lineSky3-img" src={sky3} alt="" />
              <img className="lesson-desc__lineSky3-icon" src={sky3icon} alt="" />
              <div className="lesson-desc__lineSky3-num">3</div>
              <div className="lesson-desc__lineSky3-text"><FormattedMessage id="lesson.desc.text3" defaultMessage="Ученик вместе с преподавателем разбирают теорию и выполняют тренировочные задания." /></div>
            </div>
            <div className="lesson-desc__lineSky4">
              <img className="lesson-desc__lineSky4-img" src={sky4} alt="" />
              <img className="lesson-desc__lineSky4-icon" src={sky4icon} alt="" />
              <div className="lesson-desc__lineSky4-num">4</div>
              <div className="lesson-desc__lineSky4-text"><FormattedMessage id="lesson.desc.text4" defaultMessage="В конце занятия преподаватель вместе с учеником подводит итоги и дает домашнее задание на отработку знаний." /></div>
            </div>
            <img className="lesson-desc__line-img2" src={line} alt="" />
            <div className="lesson-desc__lineSky5">
              <img className="lesson-desc__lineSky5-img" src={sky5} alt="" />
              <img className="lesson-desc__lineSky5-icon" src={sky5icon} alt="" />
              <div className="lesson-desc__lineSky5-num">5</div>
              <div className="lesson-desc__lineSky5-text"><FormattedMessage id="lesson.desc.text5" defaultMessage="После каждого урока преподаватель отмечает себе успехи ребёнка и в случае не полного освоения даёт в след.раз закрепляющие задания." /></div>
            </div>
            <div className="lesson-desc__lineSky6">
              <img className="lesson-desc__lineSky6-img" src={sky6} alt="" />
              <img className="lesson-desc__lineSky6-icon" src={sky6icon} alt="" />
              <div className="lesson-desc__lineSky6-num">6</div>
              <div className="lesson-desc__lineSky6-text"><FormattedMessage id="lesson.desc.text6" defaultMessage="Дома ученик выполняет задание и ещё раз повторяет пройденные темы." /></div>
            </div>
          </div>
        </div>
        <div className="faq">
          <span id='faq'></span>
          <div className="faq__title" ><FormattedMessage id="faq.title1" defaultMessage="Часто" /><span className="faq__title-yellow"><FormattedMessage id="faq.title2" defaultMessage=" задаваемые вопросы" /></span></div>
          <div className="accordionList" >
            <Collapsible trigger={<FormattedMessage id="about.accordion.title1" defaultMessage="На каком языке происходят занятия?" />}>
              <p><FormattedMessage id="about.accordion.title1.text1" defaultMessage="Русском" /></p>
              <p><FormattedMessage id="about.accordion.title1.text2" defaultMessage="Латышском" /></p>
              <p><FormattedMessage id="about.accordion.title1.text3" defaultMessage="Английском" /></p>
            </Collapsible>


            <Collapsible trigger={<FormattedMessage id="about.accordion.title2" defaultMessage="Какие предметы можете предложить?" />}>
              <p><FormattedMessage id="about.accordion.title2.text1" defaultMessage="Математику" /></p>
              <p><FormattedMessage id="about.accordion.title2.text2" defaultMessage="Химию" /></p>
              <p><FormattedMessage id="about.accordion.title2.text3" defaultMessage="Физику" /></p>
              <p><FormattedMessage id="about.accordion.title2.text4" defaultMessage="Русский" /></p>
              <p><FormattedMessage id="about.accordion.title2.text5" defaultMessage="Латышский" /></p>
              <p><FormattedMessage id="about.accordion.title2.text6" defaultMessage="Английский" /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title3" defaultMessage="Как проводятся занятия в группах или индивидуально?" />}>
              <p><FormattedMessage id="about.accordion.title3.text1" defaultMessage="У нас есть индивидуальные уроки – один на один, а также есть занятия в группах." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title3.1" defaultMessage="Как проводятся занятия в группах или индивидуально?" />}>
              <p><FormattedMessage id="about.accordion.title3.1.text1" defaultMessage="У нас есть индивидуальные уроки – один на один, а также есть занятия в группах." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title4" defaultMessage="Сколько длится урок?" />}>
              <p><FormattedMessage id="about.accordion.title4.text1" defaultMessage="Каждый урок длится - 45 минут, но на каждого ребёнка выделено 60 минут." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title5" defaultMessage="Сколько стоит урок?" />}>
              <p><FormattedMessage id="about.accordion.title5.text1" defaultMessage="Стоимость индивидуального урока 16 евро, занятие в группе - 8.50" /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title6" defaultMessage="У ребёнка через 2 дня большой экзамен, вы сможете его подготовить?" />}>
              <p><FormattedMessage id="about.accordion.title6.text1" defaultMessage="Мы можем попробовать. Мы не знакомы с вашим ребенком. В случае когда ребенок многое не знает, много пропустил, ответ – нет, за 2 дня не сможем. Если нужно повторить, освежить знания, дать схемы решения и закрыть хвосты – есть шанс, что да." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title7" defaultMessage="Можно чтобы учитель пришёл к нам домой?" />}>
              <p><FormattedMessage id="about.accordion.title7.text1" defaultMessage="Наши учителя не приходят на дом к ученикам. Урок может проходить или у нас в центре, или онлайн." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title8" defaultMessage="Вы работаете летом?" />}>
              <p><FormattedMessage id="about.accordion.title8.text1" defaultMessage="Да, работаем круглый год." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title9" defaultMessage="Как назначить первый урок?" />}>
              <span className='about__spanText'><FormattedMessage id="about.accordion.title9.text1" defaultMessage="Заполнить бланк " /></span>
              <span className='about__spanText'><Link to='/contacts' className='about__link' ><FormattedMessage id="about.accordion.title9.text2" defaultMessage="на сайте" /></Link></span>
              <span className='about__spanText'><FormattedMessage id="about.accordion.title9.text3" defaultMessage="" /></span>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title10" defaultMessage="Что такое тестирование?" />}>
              <p><FormattedMessage id="about.accordion.title10.text1" defaultMessage="Это проверка ребенка, что он знает и как решает. Чтобы уже первый урок был продуктивным и преподаватель понимал на каком уровне ученик и где есть пробелы в знаниях." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title11" defaultMessage="Как производится оплата за уроки?" />}>
              <p><FormattedMessage id="about.accordion.title11.text1" defaultMessage="Оплату можно совершать как за каждый урок отдельно, так и сразу за целый месяц (до 15 числа каждого месяца принимается оплата за текущий месяц). Оплатить можно: наличными или банковским перечислением." /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title12" defaultMessage="Что надо брать с собой на урок?" />}>
              <p><FormattedMessage id="about.accordion.title12.text1" defaultMessage="Полуобщую тетрадь, ручку и хорошее настроение :)" /></p>
            </Collapsible>

            <Collapsible trigger={<FormattedMessage id="about.accordion.title13" defaultMessage="Если нам нужно отменить урок?" />}>
              <span className='about__spanText'><b><FormattedMessage id="about.accordion.title13.text1" defaultMessage="Если вы знаете заранее, " /></b></span>
              <span className='about__spanText'><FormattedMessage id="about.accordion.title13.text2" defaultMessage="что не сможете в день назначенного урока – сообщите нам как можно раньше и мы перенесём урок. (Минимум за 12 часов до урока)" /></span>
              <br />
              <span className='about__spanText'><b><FormattedMessage id="about.accordion.title13.text3" defaultMessage="Если до урока осталось меньше 12 часов " /></b></span>
              <span className='about__spanText'><FormattedMessage id="about.accordion.title13.text4" defaultMessage="– отменить урок уже невозможно. Оплата за такой урок должна быть произведена за " /></span>
              <span className='about__spanText'><b><FormattedMessage id="about.accordion.title13.text5" defaultMessage="полный урок." /></b></span>
              <br />
              <br />
              <span className='about__spanText'><p><FormattedMessage id="about.accordion.title13.text6" defaultMessage="Важно! Урок отменяется в крайних случаях потому, что:" /><br />
                <FormattedMessage id="about.accordion.title13.text7" defaultMessage="Пропуск недели – шаг назад в продвижении" /><br />
                <FormattedMessage id="about.accordion.title13.text8" defaultMessage="У учителя есть расписание и он готовится к каждому уроку. Каждый час им важен, это их хлеб." /></p></span>
            </Collapsible>
          </div>
        </div>

        <div className="why-school">
          <div className="why-school__excursion">
            <div className="why-school__excursion-title">
              <FormattedMessage id="home.why.school1" defaultMessage="Откройте для себя новый мир вместе с математическим центром Ларисы Вагалэ!" /><br />
              <div className="why-school__title-yellow"><FormattedMessage id="home.why.school2" defaultMessage="Знания - важный шаг к успеху!" /></div>
              <img className="why-school__excursion-icon" src={likeButton} alt=""></img>
            </div>
            <span className="why-school__excursion-button" >
              <Link to='/contacts'>
                <FormattedMessage id="home.button2" defaultMessage="Забронировать дату и время" />
              </Link>
            </span>
          </div>
        </div>
        <span id='teachers'></span>
        <div className="teachers" >
          <div className="teachers__title"  > <FormattedMessage id="home.teachers.header1" /><span className="teachers__title-yellow"><FormattedMessage id="home.teachers.header2" /></span><FormattedMessage id="home.teachers.header3" /></div>
          <div className="teachers__list">
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_larisa} alt="" />
                </div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name1" defaultMessage="Лариса" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text1" defaultMessage="Директор математического центра" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_din} alt="" />
                </div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name2" defaultMessage="Дин" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text2" defaultMessage="Зам.директора математического центра" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_anastasija} alt="" />
                </div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name4" defaultMessage="Анастасия" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text4" defaultMessage="" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="teachers__list">
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_jelena_lazareva} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name3" defaultMessage="Елена" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text3" defaultMessage="Математика для начальной школы" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_violetta} alt="" />
                </div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name6" defaultMessage="Виолетта" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text6" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_anna_m} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name5" defaultMessage="Анна" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text5" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_regina} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name7" defaultMessage="Регина" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text7" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_olga} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name8" defaultMessage="Ольга" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text8" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_igor} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name9" defaultMessage="Игорь" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text9" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_jevgenija} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name10" defaultMessage="Евгения" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text10" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_angelika} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name11" defaultMessage="Ангелика" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text11" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_jekaterina_m} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name12" defaultMessage="Екатерина" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text12" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_ludmila} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name13" defaultMessage="Людмила" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text13" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_erik} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name14" defaultMessage="Эрик" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text14" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_anelija} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name15" defaultMessage="Анелия" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text15" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_jelena_a} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name16" defaultMessage="Елена" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text16" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_polina} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name17" defaultMessage="Полина" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text17" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_jekaterina} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name18" defaultMessage="Екатерина" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text18" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_megan} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name19" defaultMessage="Меган" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text19" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_logo} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name20" defaultMessage="Мария" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text20" defaultMessage="" /></div>
                </div>
              </div>
            </div>
            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_anna_a} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name21" defaultMessage="Анна" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text21" defaultMessage="" /></div>
                </div>
              </div>
            </div>

            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_alla} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name22" defaultMessage="Алла" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text22" defaultMessage="" /></div>
                </div>
              </div>
            </div>

            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_elizabeth} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name23" defaultMessage="Елизавета" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text23" defaultMessage="" /></div>
                </div>
              </div>
            </div>

            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_daniil} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name24" defaultMessage="Даниил" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text24" defaultMessage="" /></div>
                </div>
              </div>
            </div>

            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_victoria} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name25" defaultMessage="Виктория" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text25" defaultMessage="" /></div>
                </div>
              </div>
            </div>

            <div className="teachers__list-item--bigger">
              <div className="teachers__list-item--bigger-content">
                <div className="teachers__list-item--bigger-content__icon"><img src={teacher_andrey} alt="" /></div>
                <div className="teachers__list-item--bigger-content__info">
                  <div className="teachers__list-item--bigger-content__info-title"><FormattedMessage id="teacher.name26" defaultMessage="Андрей" /></div>
                  <div className="teachers__list-item--bigger-content__info-text"><FormattedMessage id="teacher.text26" defaultMessage="" /></div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="feedback" id="feedback">
          <div className="feedback__title"><FormattedMessage id="home.feedback.header1" /><FormattedMessage id="home.feedback.header2" /><span className="feedback__title--yellow"><FormattedMessage id="home.feedback.header3" /></span><FormattedMessage id="home.feedback.header4" /></div>
          <div className="feedback__list">
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback1} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback1.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback1.body" /></div>
            </div>
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback2} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback2.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback2.body" /></div>
            </div>
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback3} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback3.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback3.body" /></div>
            </div>
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback4} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback4.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback4.body" /></div>
            </div>
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback5} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback5.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback5.body" /></div>
            </div>
            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback6} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback6.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback6.body" /></div>
            </div>

            <div className="feedback__list-item">
              <div className="feedback__list-item-header">
                <div className="feedback__list-item-header__icon">
                  <img src={feedback7} alt="" />
                  <div className="feedback__list-item__icon-title"><FormattedMessage id="home.feedback7.name" /></div>
                </div>
                <div className="feedback__list-item-header__logo">
                  <FaFacebook />
                </div>
              </div>
              <div className="feedback__list-item__text"><FormattedMessage id="home.feedback7.body" /></div>
            </div>


            
          </div>
        </div>


      </div>
      <Footer />
    </div>
  );
};

export default Home;